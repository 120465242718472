import React from 'react';
import './styles.scss';
import CTAButtons from '../../CTAButtons';
import Fade from 'react-reveal/Fade';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;
const teamsAddUrl = process.env.GATSBY_TRIVIA_MS_TEAMS_URL;

class Header extends React.Component {
    render() {
        return (
            <Fade top cascade>
                <div className='securityHeaderWrap'>
                    <div className='securityHeader'>
                        <h1>We built Trivia with Privacy as our main ingredient</h1>
                        <p>
                            We care deeply about your privacy and we will always respect your trust. This is clearly
                            reflected in our privacy policies and certifications.{' '}
                        </p>
                    </div>
                    <CTAButtons
                        slackButtonID='TRIVIA-SLACK-INSTALL'
                        teamsButtonID='TRIVIA-TEAMS-INSTALL'
                        slackAddUrl={slackAddUrl}
                        teamsAddUrl={teamsAddUrl}
                    />
                </div>
            </Fade>
        );
    }
}

export default Header;
