import React from 'react';
import './styles.scss';
import GlobalClient from '../../GlobalClient';

class Clients extends React.Component {
    render() {
        return (
            <div className='securityClientWrap'>
                <GlobalClient />
            </div>
        );
    }
}

export default Clients;
