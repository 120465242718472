import React from 'react';
import './styles.scss';
import { Bounce, Fade } from 'react-reveal';

class EnterpriseGrade extends React.Component {
    render() {
        return (
            <div className='triviaContainer'>
                <div className='securityEnterpriseWrap'>
                    <div className='securityEnterpriseLeft'>
                        <Bounce top>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/security/enterprise-illustration.jpg?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/trivia/security/enterprise-illustration.jpg'}
                                alt={''}
                            />
                        </Bounce>
                    </div>
                    <div className='securityEnterpriseRight'>
                        <Fade right cascade>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/security/enterprise-icon.svg?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/trivia/security/enterprise-icon.svg'}
                                alt={''}
                            />
                            <h3>Enterprise-grade security</h3>
                            <p>
                                All our measures are enterprise-ready to make it easy for your company to hit the ground
                                up and running instantly.{' '}
                            </p>
                        </Fade>
                    </div>
                </div>
            </div>
        );
    }
}

export default EnterpriseGrade;
