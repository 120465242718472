import React from 'react';
import Navbar from '../../../components/trivia/NavbarOld';
import Header from '../../../components/trivia/Security/Header';
import Footer from '../../../components/trivia/Footer';
import './styles.scss';
import { Helmet } from 'react-helmet';
import Clients from '../../../components/trivia/Security/Clients';
import EnterpriseGrade from '../../../components/trivia/Security/EnterpriseGrade';
import LeadingIndustry from '../../../components/trivia/Security/LeadingIndustry';
import ScaleGrowth from '../../../components/trivia/Security/ScaleGrowth';

class Security extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Enterprise-Grade Security | Trivia </title>
                    <meta
                        name='description'
                        content='We at Trivia are committed to robust security and data protection policy. All our security measures are stringent and adhere to strong privacy commitments.'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Enterprise-Grade Security | Trivia ' />
                    <meta
                        property='og:description'
                        content='We at Trivia are committed to robust security and data protection policy. All our security measures are stringent and adhere to strong privacy commitments.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/security/' />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:image'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaSecurity.jpg'
                    />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaSecurity.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta property='twitter:title' content='Enterprise-Grade Security | Trivia' />
                    <meta
                        property='twitter:description'
                        content='We at Trivia are committed to robust security and data protection policy. All our security measures are stringent and adhere to strong privacy commitments.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/TriviaSecurity.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/security/' />
                </Helmet>
                <div className='pageWrap customerWrap'>
                    <Navbar />
                    <Header />
                    <Clients />
                    <EnterpriseGrade />
                    <LeadingIndustry />
                    <ScaleGrowth />
                    <Footer />
                </div>
            </>
        );
    }
}
export default Security;
