import React from 'react';
import './styles.scss';
import { Bounce, Fade } from 'react-reveal';
class ScaleGrowth extends React.Component {
    render() {
        return (
            <div className='scaleGrowth'>
                <div className='scaleGrowthInner'>
                    <Fade left cascade>
                        <div className='scaleGrowthLeft'>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/security/growth-icon.svg?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/trivia/security/growth-icon.svg'}
                                alt={''}
                            />
                            <h4>Security that scales with your growth</h4>
                            <p>
                                We maintain fully compliant data centers that allow sensitive data to be stored securely
                                through state-of-the-art tech that’s scalable at all times.
                            </p>
                        </div>
                    </Fade>
                    <Bounce top>
                        <div className='scaleGrowthRight'>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/security/illustration.png?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/trivia/security/illustration.png'}
                                alt={''}
                            />
                        </div>
                    </Bounce>
                </div>
            </div>
        );
    }
}

export default ScaleGrowth;
