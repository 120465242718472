import React from 'react';
import './styles.scss';
import Fade from 'react-reveal';

class LeadingIndustry extends React.Component {
    render() {
        return (
            <>
                <div className='securityLeadingIndustryWrap'>
                    <div className='GDPRCompliantWrap'>
                        <div className='GDPRCompliantLeft'>
                            <img
                                src={'https://sw-assets.gumlet.io/trivia/security/gdpr.svg?blur=30'}
                                data-src={'https://sw-assets.gumlet.io/trivia/security/gdpr.svg'}
                                alt='gdpr'
                            />
                        </div>
                        <div className='GDPRCompliantRight'>
                            <h4>GDPR Compliant</h4>
                            <p>
                                Trivia is GDPR ready. Meaning, you can rest assured that all your personal information
                                is secured in compliance with the latest EU laws.
                            </p>
                        </div>
                    </div>
                    <div className='triviaContainer'>
                        <Fade left>
                            <div className='securityLeadingIndustryHeader'>
                                <h2>Industry Leading Security</h2>
                            </div>
                        </Fade>

                        <div className='securityLeadingIndustryInner'>
                            <div className='securityLeadingIndustryTop'>
                                <Fade Left cascade>
                                    <div className='securityLeadingIndustry'>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/security/compliance.svg?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/security/compliance.svg'}
                                            alt=''
                                        />
                                        <h4>Compliance certifications</h4>
                                        <p>
                                            Trivia is committed to maintaining strong data protection commitments.
                                            Rightly so we’re certified and recognized by ISO and PCI.{' '}
                                        </p>
                                    </div>
                                    <div className='securityLeadingIndustry'>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/security/user.svg?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/security/user.svg'}
                                            alt=''
                                        />
                                        <h4>User Privacy</h4>
                                        <p>
                                            We have got a formal Privacy Policy in place to keep you covered. With
                                            best-in-class security standards, all data backups are protected by
                                            stringent measures.{' '}
                                        </p>
                                    </div>
                                </Fade>
                            </div>
                            <div className='securityLeadingIndustryTop'>
                                <Fade Right cascade>
                                    <div className='securityLeadingIndustry'>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/security/empowered.svg?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/security/empowered.svg'}
                                            alt=''
                                        />
                                        <h4>Empowered by encryption</h4>
                                        <p>
                                            All data is transmitted over HTTPS, and any data stored is encrypted with
                                            256-bit encryption. Our application endpoints are TLS/SSL/DCMA/SMAL placing
                                            us right amongst the industry pioneers.
                                        </p>
                                    </div>
                                    <div className='securityLeadingIndustry'>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/security/permissions.svg?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/security/permissions.svg'}
                                            alt=''
                                        />
                                        <h4>Permissions and Authentication</h4>
                                        <p>
                                            Access to user data is limited to authorized employees only. Additionally,
                                            our firewall policies and constant monitoring ensure your data is guarded
                                            without compromise.
                                        </p>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <Fade left>
                            <div className='securityLeadingIndustryHeader'>
                                <h2>Our Security Commitment</h2>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className='securityCommitment'>
                    <div className='securityCommitmentContent'>
                        <Fade Right>
                            <div className='securityCommitmentSection'>
                                <h5>Quick implementation</h5>
                                <p>Get your company using Trivia in hours, not days with 99.5% uptime SLA.</p>
                            </div>
                            <div className='securityCommitmentSection'>
                                <h5>Secure and user-friendly</h5>
                                <p>
                                    Security and governance functionality of an enterprise solution coupled with
                                    intuitive user experience.
                                </p>
                            </div>
                            <div className='securityCommitmentSection'>
                                <h5>Dedicated support</h5>
                                <p>Priority support with a dedicated customer success manager.</p>
                            </div>
                        </Fade>
                    </div>
                </div>
            </>
        );
    }
}

export default LeadingIndustry;
